import Nav from './Nav.vue'
import {Fragment} from '../../global-styles'

const Navbar ={
    name: 'Navbar',
    render(){
        return(
            <Fragment>
             <Nav/>
            </Fragment>
        )
    }
}


export default Navbar;