import axios from 'axios'


class HTTP {
   static Fetch(){
            const c_BaseURL = 'https://brainomix-web-media.s3-eu-west-1.amazonaws.com/recruitment/ui-challenge/ui_developer_test1.json';
            const HTTP = axios.create({
              baseURL: c_BaseURL,
              
              headers: {
                'Content-Type': 'application/json',
              }
          });               

            return HTTP;
  }
}



  export default HTTP.Fetch();

  