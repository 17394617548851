import styled from 'vue-styled-components'


export const Links = styled.a`
    @import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif;
    text-decoration: none;
    padding: 0px 20px;
`

export const Logo = styled.div`
    
`

export const Nav_Links = styled.div`
margin-top: 32px;
@media (max-width: 680px) {
   visibility: hidden;
  }

`

export const Nav = styled.div`
    width: 100%;
    height: 100px;
   
`


export const RightAlign = styled.div`
     margin-right: auto;
`

export const LeftAlign = styled.div`
     margin-left: auto;
`


export const Inner_Nav = styled.div`
   display: flex;
   
`

export const Button = styled.div`
    background-color: #138496;
    height: 26px;
    width: 80px;
    padding: 10px;
    cursor: pointer;
    font-weight: 900;
    padding-right: 20px;
    color: white;
    border-radius: 12px;
    display: inline-block;
    @media (min-width: 730px) and (max-width: 1130px){
               padding-right: 5px;
        }

`