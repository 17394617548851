<script>
            import {Home_heading, List, Grid, ToolBar, Tableview, Grid_Inner, SortBar} from './style'
            import { Fragment} from '../../global-styles'
            import {Button} from '../../components/Navbar/style'
            import HTTP from '../../util/http_common'
            import Card from '../../components/Card/Card'
            import Table from '../../components/Tableview/Table.vue'
            

            export default {
                async mounted(){

                        const requestingData = await HTTP.get();
                        const PreciseData = requestingData.data.items;
                        this.data = PreciseData; 
                        this.apiFetchedData = PreciseData;                      
                    },

                    data () {
                        return {
                            data: [],
                            apiFetchedData: [],
                            viewGrid: true,
                            personSearch:  '',
                            timestampSearch : '',
                            patientIdSearch: '',
                            
                        }
                    },

                     methods: {
                         change: function(){
                             this.viewGrid = !this.viewGrid;
                         },

                         NameSearch: function(){
                           var Query = this.personSearch;
                           Query = Query.toUpperCase();
                           var filteredData  = [];
                           this.apiFetchedData.forEach(function(aPatient){
                               var PatientName = aPatient.patient_name;                              
                               if(PatientName !== null){
                                    PatientName = PatientName.toUpperCase();
                                  if(PatientName.indexOf(Query) !== -1){
                                      filteredData.push(aPatient);
                                  }                                   
                               }
                           })
                                    this.data = filteredData;
                                    if(this.personSearch.length === 0){
                                        this.data = this.apiFetchedData;
                                    }

                                    this.timestampSearch = '';
                                     this.patientIdSearch = '';
                                    
                         },

                         sortByTime: function(){
                          var Query = this.timestampSearch;
                             var filteredData  = this.apiFetchedData.filter((item)=>{
                                 var TimeofScan = item.timestamp;
                                 return TimeofScan === Query;
                             })
                             this.data = filteredData;
                               this.personSearch = '';
                            this.patientIdSearch = '';
                             
                         },

                         sortByPatient : function(){
                            var Query = this.patientIdSearch;
                             var filteredData  = this.apiFetchedData.filter((item)=>{
                                 var PatientID = item.patient_id;
                                 return PatientID === Query;
                             })
                             this.data = filteredData;
                             this.personSearch = '';
                             this.timestampSearch = '';
                         },
                         reset: function(){
                             this.personSearch = '';
                            this.timestampSearch = '';
                            this.patientIdSearch = '';
                             this.data = this.apiFetchedData;
                         }
                         
                     },


                 components : {
                     Home_heading,
                     Fragment,
                     Card, 
                     List, 
                     Grid,
                     ToolBar, 
                     Tableview,
                     Table,
                     Grid_Inner,
                     Button,
                     SortBar

                 }
            
            }
</script>


<template>
    <Fragment>
        <Grid>
             <Home_heading style="width: 130px;">    AI Enabled Precision </Home_heading>
             <div>
                 <img class="brand-img" src="https://www.brainomix.com/media/robn531e/global-expertise-1.svg?width=800&mode=max"/>
             </div>

        </Grid>
        <br/>
        <br/>
           
            <Home_heading class="heading2">We specialize in the creation of AI-powered imaging biomarkers that enable precision medicine for better treatment decisions </Home_heading>
            <br/>
            <br/>
            <br/>

                     <Fragment v-if="apiFetchedData.length === 0">
                          <Home_heading class="home-result">  404 - That's an error  </Home_heading>
                    </Fragment>  
                    <Fragment v-else>

                    
                    



                    <ToolBar>                       
                    <div class="inner" v-if="viewGrid === true">
                    <i class="fas change spaces fa-2x fa-th-large"></i>
                    <i v-on:click="change()" class="fas last spaces fa-2x fa-th-list"></i>
                    </div>   

                    <div class="inner" v-else>
                    <i v-on:click="change()" class="fas spaces fa-2x fa-th-large"></i>
                    <i  class="fas change last spaces fa-2x fa-th-list"></i>
                    </div>   
                    </ToolBar>
                    <SortBar>
                     <input type="text" v-model="personSearch" @change="NameSearch()" placeholder="Search for Patient Name .." />

                    <select class="select-dark"  v-model="patientIdSearch" @change="sortByPatient()">
                        <option value="" selected disabled hidden> Patient ID </option>
                        <option  v-for="items in apiFetchedData" :key="items.id">{{items.patient_id}}</option>

                    </select>

                     <select class="select-dark-time"  @change="sortByTime()" v-model="timestampSearch"  >
                          <option value="" selected disabled hidden> Time of Scan </option>
                        <option v-bind:value="items.timestamp" v-for="items in apiFetchedData" :key="items.id">{{items.timestamp}}</option>
                    </select>
                    
                    <Button v-on:click="reset()" class="reset"> Reset </Button>

                    </SortBar>
                             <br/> 
                               <Home_heading class="home-result">    Precision Results  </Home_heading>
                               <Fragment v-if="data.length == 0"> 
                                    <Home_heading class="notfound">No Result Found</Home_heading>
                               </Fragment>            
                                <br/>
                                <br/> 
                        <Grid v-if="viewGrid=== true">
                                <List v-for="item in data" :key="item.id">
                                <Card :dataset={item} />
                                </List>
                        </Grid>

                        <Tableview v-if="viewGrid === false" >
                        <div v-for="item in data" :key="item.id">
                        <Table :dataset={item} />
                        </div>                     

                        </Tableview>

                    </Fragment> 
                        <br/>
                        <br/>
                        <br/>

                         <Grid_Inner>
                             
                            <Home_heading class="inner-text" >  The Most Comprehensive Stroke Imaging Solution</Home_heading>
                             
                            <div>
                                <img class="brand-img-last" src="https://www.brainomix.com/media/b2sakxet/e-stroke_5-devices-2021.png?width=800&mode=max"/>
                            </div>

                            </Grid_Inner>
                            
                                 
    </Fragment>
    
</template>

<style>
    .heading2{
        font-size: 25px !important;
    }
    .spaces{
        padding: 2px 10px 20px 2px;
        color: #404040;
        cursor: pointer;
    }
    .spaces:hover{
        color: red;
    }
    .inner-text{
        text-align: left;
        padding: 10px;
        font-size: 3.5rem !important;
        padding-right: 10vw !important;
    }
 
    .change{
        color: red;
    }
    .last{
        padding-right: 50px;
    }

    .inner{
        text-align: right;
    }

    .home-result{
        font-size: 53px !important;
        color: #404040 !important;
    }
    
    .notfound{
        font-size: 27px !important;
        padding-top: 30px;
        color: #F44336 !important;
    }

    .reset{
        font-size: 22px;
         font-family: 'Ubuntu', sans-serif;
         margin-left: auto;
         width: 55px;

    }

    @media screen and (max-width: 789px) {
        .inner-section-lower{
                 width: 300px  !important;
                 font-size: 60px;
                 text-align: center;                
            }

            .reset{
                margin-left: 30px;
                 width: 70px;
            }
            
            .brand-img-last{
                width: 230px !important;
            }
            .inner-text{
             font-size: 2.0rem !important;
            padding-right: 0 !important;
            } 

           
        
    }


    @media only screen and (min-width: 790px) and (max-width: 1200px){
        .reset{
            margin-left: 30px;
            width: 70px;
        }
    }

    @media only screen and (min-width: 790px) {

            .brand-img{
                width: 800px !important;
               padding-top: 100px !important;
            }
                 .inner-section-lower{
                width: 700px  !important;
                font-size: 60px;
            }
            
             .inner-text{
            padding-left: 5vw;
            }
            
            
        }

        .brand-img-last{
            width: 400px;
        }

        .inner-section{
            width: 640px  !important;
        }
            
        input[type=text] {
            width: 30%;
            padding: 12px 20px;
            margin: 8px 0;
            box-sizing: border-box;
            font-family: 'Ubuntu', sans-serif;
             color: #404040;
            font-size: 15px;
        }    
        .select-dark{
            width: 14%;
            padding: 12px 20px;
            margin: 8px 0;
            box-sizing: border-box;
            font-family: 'Ubuntu', sans-serif;
            font-size: 16px;

        }
        .select-dark-time{
            width: 18%;
            padding: 12px 20px;
            margin: 8px 0;
            box-sizing: border-box;
            font-family: 'Ubuntu', sans-serif;
            font-size: 16px;

        }
            
            

   

</style>

