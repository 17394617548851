import styled from 'vue-styled-components'

export const Heading = styled.h1`
color: #404040;
font-size: 15px;
font-family: 'Roboto', sans-serif;
line-height: 0;
`

export const Heading2 = styled.h1`
color: #404040;
font-family: 'ubuntu',sans-serif;
font-size: 17px;
font-weight: 700;
`