import styled from 'vue-styled-components'

export const Footer = styled.footer`
    padding: 3rem 0 1rem 0;
    background-color: #232127 !important;
    border-radius: 10px 10px 0px 0px;
    margin-top: 30px;
    padding-bottom: 30px;
`

export const Heading = styled.h1`
font-size: 38px;
color: white;
font-family: 'Montserrat', sans-serif ;
padding-left: 100px;
@media (max-width: 730px){
        padding-left: 30px;
        padding-left: 10px;
        }

        @media (min-width: 730px) and (max-width: 1130px){
                padding-left: 30px;
                padding-left: 10px;
        }
`


export const Grid = styled.div`
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(3, 1fr); 
        margin: auto;
        @media (max-width: 730px){
                grid-template-columns: repeat(1, 1fr); 
                text-align: center;
        }

        @media (min-width: 730px) and (max-width: 1130px){
                grid-template-columns: repeat(1, 1fr); 
                text-align: center;
        }
`

export const  List = styled.ul`

`

export const Item = styled.li`
    font-size: 19px;
    text-decoration: none;
    color: white;
    list-style: none;
    padding: 9px 0px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif ;
    cursor: pointer;
    &:hover {
               color: green; 
        }
`