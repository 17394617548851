import styled from 'vue-styled-components'

export const Home_heading = styled.div`
        @import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&family=Montserrat:wght@900&display=swap');
        font-family: 'Montserrat', sans-serif ;
        font-weight: 800;
        color: #404040;
        font-size: 500%;

        @media (max-width: 730px){
             font-size: 17vw;
        }
                
`


export const SortBar = styled.div`
display: flex;
margin-top: 30px;
margin-bottom: 30px;

@media (max-width: 1000px){
        display: block;
}

`


export const Grid = styled.div`
        padding: 0px 20px !important;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(3, 1fr); 
        margin: auto;
        @media (max-width: 730px){
                grid-template-columns: repeat(1, 1fr); 
        }

        @media (min-width: 730px) and (max-width: 1130px){
                grid-template-columns: repeat(2, 1fr); 
        }
`

export const Grid_Inner = styled.div`
        padding: 0px 20px !important;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(3, 1fr); 
        margin: auto;
        @media (max-width: 730px){
                grid-template-columns: repeat(1, 1fr); 
                
        }

        @media (min-width: 730px) and (max-width: 1130px){
                grid-template-columns: repeat(1, 1fr); 
                text-align: center;
        }
`

export const Tableview = styled.div`
        
`

export const ToolBar = styled.div`
        height: 55px;
`

export const List = styled.div`
   
`


export const RightAlign = styled.div`
     margin-right: auto;
`

export const LeftAlign = styled.div`
     margin-left: auto;
`