<script>
    import {Links, Nav, Inner_Nav, LeftAlign, RightAlign, Logo, Nav_Links, Button}
     from './style';
    import {Container, Fragment} from '../../global-styles'
    import { PushRotate } from 'vue-burger-menu'


    export default {
        name: 'Navbar',
         components : {
            'Links': Links,
            'Nav': Nav,
            'Container': Container,
            'Fragment': Fragment,
            'Inner_Nav': Inner_Nav,
            'LeftAlign': LeftAlign,
            'RightAlign': RightAlign, 
            'Logo': Logo,
            'Nav_Links': Nav_Links,
            PushRotate,
            Button
            }

    }
</script>

<template>
    <Fragment>
        <Nav>
            <Container>
                 <Inner_Nav>
                        <RightAlign>
                                            <PushRotate width="340">
                                            <Links class="text-light" href="https://www.brainomix.com/"> <i class="fas fa-size fa-brain space"></i> Company </Links>
                                            <Links class="text-light" href="https://www.brainomix.com/"> <i class="fas fa-qrcode fa-size space"></i> About </Links>
                                            <Links class="text-light" href="https://www.brainomix.com/"> <i class="fas fa-flag-checkered fa-size space"></i>  Reports </Links>
                                            <Links class="text-light" href="https://www.brainomix.com/"> <i class="fas fas fa-air-freshener fa-size space"></i>  AI in Medical </Links>
                                            <Links class="text-light" href="https://www.daimdev.com"> <i class="fas fa-code fa-size space"></i>  Developer </Links>
                                            </PushRotate>
                                <Logo>
                                <img class="Logo" src="https://www.brainomix.com/images/brainomix-logo.svg" alt="brainomix Logo"/>
                                </Logo>
                        </RightAlign>
                         <LeftAlign>
                                    <Nav_Links>                                          
                                            <Links href="https://www.brainomix.com/"> Company </Links>
                                            <Links  href="https://www.brainomix.com/"> About </Links>
                                            <Links  href="https://www.brainomix.com/"> Reports </Links> 
                                            <Button> <Links class="button-link" href="https://www.brainomix.com/"> Demo </Links> </Button>                                         
                                    </Nav_Links>                                 
                         </LeftAlign>
                 </Inner_Nav>                
            </Container>          
        </Nav>
    </Fragment>
     
    
</template>


<style>
    .Logo{
        width: 240px;
        margin-top: 30px;
        padding-left: 70px;
    }
    .space{
        padding: 0px 10px;
    }
    .fa-size{
        font-size: 25px;
    }
    .text-light{
        color: #D0D0D0;
    }
    .button-link{
        color: white;
    }
</style>





