import Navbar from './components/Navbar/Navbar'
import { 
    Container,
    Fragment
} from './global-styles';

import Home from './pages/Home/Home.vue'
import Footer from './components/Footer/footer.vue'


const App =  ()=> {
    return (
        <Fragment>
        <Navbar/>
                <Container>   
                    <Home/>     
                </Container>
                <Footer/> 
        </Fragment>         
    )
}

export default {

    
   
    render(){
        return(
            <App/>          
        )
    }

}
