<script>
                import {Fragment} from '../../global-styles'
                import { Heading2} from './style'

                export default{
                    components :{
                        Fragment, 
                        Heading2
                    },

                    view: function(resource){
                        window.location.href = resource;
                    },

                     props :{
                            dataset: Object,
                        
                        }, 
                }
</script>

<template>

        <Fragment>
        <div class="horizontal-card">
         <img v-on:click="view(`${dataset.item.url}`)" class="img-fluids" v-bind:src="dataset.item.thumbnail" alt=""/>
        <div class="horizontal-card-body">
         <table id="">
                        <tbody>
                        <tr>
                        <td><Heading2>Patient  ID    :  </Heading2></td>
                         <td v-if="dataset.item.patient_id == null"> <Heading2>N/A </Heading2></td>
                        <td v-else> <Heading2>{{dataset.item.patient_id}}  </Heading2></td>
                        </tr>
                        <tr>
                        <td><Heading2> Patient Name :  </Heading2></td>
                        <td v-if="dataset.item.patient_name == null"> <Heading2>N/A </Heading2></td>
                        <td v-else> <Heading2>{{dataset.item.patient_name}}  </Heading2></td>
                        </tr>
                        <tr>
                        <td><Heading2>Patient Date of Birth :  </Heading2></td>
                         <td v-if="dataset.item.patient_dob == null"> <Heading2>N/A </Heading2></td>
                        <td v-else> <Heading2>{{dataset.item.patient_dob}}  </Heading2></td>
                        </tr>
                        <tr>
                        <td><Heading2> Time :  </Heading2></td>
                         <td v-if="dataset.item.timestamp == null" > <Heading2>{{dataset.item.timestamp}}  </Heading2></td>
                        <td v-else > <Heading2>{{dataset.item.timestamp}}  </Heading2></td>
                        </tr>
                        <tr>
                        <td><Heading2> Status :  </Heading2></td>                         
                        <td> <Heading2>{{(dataset.item.state).toUpperCase()}} </Heading2></td>
                        </tr>
                        <tr>
                        <td><Heading2> Slice Thickness :  </Heading2></td>
                         <td v-if="dataset.item.slice_thickness == ''" > <Heading2>N/A</Heading2></td>
                        <td v-else > <Heading2>{{dataset.item.slice_thickness}}  </Heading2></td>
                        </tr>
                         <tr>
                        <td><Heading2> Scanner Manufacturer :  </Heading2></td>
                         <td v-if="dataset.item.scanner_manufacturer == ''" > <Heading2>N/A</Heading2></td>
                        <td v-else > <Heading2>{{dataset.item.scanner_manufacturer}}  </Heading2></td>
                        </tr>
                         <tr>
                        <td><Heading2> Scanner Model :  </Heading2></td>
                         <td v-if="dataset.item.scanner_model == ''" > <Heading2>N/A  </Heading2></td>
                        <td v-else > <Heading2>{{dataset.item.scanner_model}}  </Heading2></td>
                        </tr>
                         <tr>
                        <td><Heading2> Series Description :  </Heading2></td>
                         <td v-if="dataset.item.series_description == ''" > <Heading2>N/A </Heading2></td>
                        <td v-else > <Heading2>{{dataset.item.series_description}}  </Heading2></td>
                        </tr>
                         <tr>
                        <td><Heading2> Study Description :  </Heading2></td>
                         <td v-if="dataset.item.study_description == ''" > <Heading2> N/A</Heading2></td>
                        <td v-else > <Heading2>{{dataset.item.study_description}}  </Heading2></td>
                        </tr>
                        
                        </tbody>
                        </table>
        </div>
                
        </div>
        </Fragment>

    
</template>

<style>
            .horizontal-card {
            position: relative;
            display: flex;
            border: 1px solid gray;
            margin-bottom: 1rem;
            }
            .horizontal-card img {
            width: 200px;
            height: 130px;
            }
            .horizontal-card .horizontal-card-body {
            display: flex;
            flex-direction: column;
            margin-left: 1rem;
            padding-top: 14px;
            }
            .horizontal-card .horizontal-card-footer {
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            height: 30px;
            display: flex;
            align-items: center;
            }
            .horizontal-card .horizontal-card-footer span {
            width: 200px;
            display: inline-block;
            }
            .horizontal-card .horizontal-card-footer a {
            margin-left: 10px;
            }
            .img-fluids{
            width: 400px !important;
            height: 500px !important;
            cursor: pointer;
            transition: all 0.3s;
            display: block;
            height: auto;
            transform: scale(1); 
            
           
        }

        @media only screen and (min-width: 580px) and (max-width: 780px){
                    .img-fluids{
                        width: 200px !important;
                        height: 200px !important;
                    }

                    }

                    @media only screen and  (max-width: 580px){
                    .img-fluids{
                        width: 80px !important;
                        height: 80px !important;
                    }

                    }
        
</style>
