import styled from 'vue-styled-components'

export const Container = styled.div`
width: 95%;
margin: auto;
`

export const Fragment = styled.div`
margin: auto;
`

const colorProps = {native: Boolean};

export const Heading = styled('div', colorProps)`
color: ${props => props.primary ? 'black' : 'palevioletred'};
`