<script>
            import {Footer, Heading, List, Item, Grid} from './style'
            import {Container, Fragment} from '../../global-styles'
            export default {
                name: 'footer',
                components:{
                    Footer,
                    Heading,
                    List,
                    Item,
                    Grid,
                    Container, 
                    Fragment
                }
            }
</script>



<template>
<Fragment>
<br/>
<br/>
 <Footer class="Align">
           <Container >
               
                    <Grid>
                    <Heading>
                    Features
                         <List>
                        <Item>Brainomix</Item>
                        <Item>Branding Links</Item>
                        <Item>Analytics</Item>
                    </List>
                    </Heading>
                    
                   
              
                    <Heading> Resources 
                           <List>
                       <Item>Blog</Item>
                       <Item>Developer</Item>
                       <Item>Support</Item>
                    </List>  

                    </Heading>
                   
               
                    <Heading> Company                        
                         <List>
                       <Item>About</Item>
                       <Item>Our Team</Item>
                       <Item>Career</Item>
                       <Item>Contact</Item>
                    </List>
                     </Heading>
                   
               
                
                    </Grid>
                    <Fragment class="copyright">

                        2021 - All right reserved | Developed By Daim
                    </Fragment>

                   
           </Container>
 </Footer>

</Fragment>
    
</template>



<style>
  

    .copyright{
        font-size: 18px;
        color: white;
        text-align: center;
         font-family: 'Montserrat', sans-serif ;
         padding-bottom: 40px;
    }
  
</style>