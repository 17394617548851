import styled from 'vue-styled-components'

export const Card = styled.div`
 box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin: 10px 30px;
  width: 300px;
  @media (max-width: 730px){
                width: 280px; 
                margin: 10px 7px;
        }
        @media (min-width: 730px) and (max-width: 1130px){
               width: 260px;
        }
`

export const Card_container = styled.div`
  padding: 2px 16px;

`


export const Heading = styled.h1`
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
color: #404040;
font-family: 'ubuntu',sans-serif;
font-size: 13px;
font-weight: 300;
`

export const Heading2 = styled.h1`
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
color: #404040;
font-family: 'ubuntu',sans-serif;
font-size: 17px;
font-weight: 700;
`


const colorProps = {failed: Boolean};

export const Alert = styled('div', colorProps)`
      height: 50px;
      background: ${props => props.failed ? 'palevioletred' : '#0288D1' };
      border-radius: 10px;
      padding: 0px 20px;
      text-align: center;
      cursor: pointer;
`


export const Button = styled.div`
      height: 50px;
      font-size: 12px;
      border-radius: 10px;
      padding: 0px 20px;
      text-align: center;
      color: #404040;
`



export const AlertText = styled.h3`
color: white;
padding-top: 10px;
`

export const Loader = styled.div`
position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  @media (max-width: 1100px) {
   height: 300vh;
  }

`

export const ItemLoader = styled.div`
position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  margin: auto;
  @media (max-width: 680px) {
   height: 300vh;
  }

`

export const InnerLoader = styled.div`
margin: auto;
background-color: white;
height: 90vh;
margin-top: 5vh;
width: 100%;
overflow-y: auto;
`