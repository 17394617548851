<script>

        import {Card, Heading, Card_container, Alert, AlertText, Loader, ItemLoader,
                InnerLoader, Heading2
        } from './style'
        import {Links} from '../Navbar/style'
        import {Fragment, Container} from '../../global-styles'


   export default {
        components : {
            Card, 
            Fragment, 
            Heading,
            Card_container, 
            Alert, 
            AlertText, 
            Loader, 
            ItemLoader,
            Container,
            InnerLoader,
            Heading2,
            Links
            
        }, 
        props :{
            dataset: Object,
           
        }, 

        data () {
                 return {
                    isViewLoaded: false,
                    isImageLoaded : false,
                    ImageUrl : "",
                    viewTarget: {}

                 }
            },

        methods: {
            view: function(resource){
                this.ImageUrl = resource;
                this.isImageLoaded = true;
               
               // window.location.href = this.target;
            },

            viewdetails: function(resource){
                this.isViewLoaded= true;
                this.viewTarget = resource;
            },

            remove: function(){
                this.isViewLoaded = false;
            },
            removeImage: function(){
                this.isImageLoaded = false;
            }
        }
   }
</script>


<template>
    <Fragment>
        
        <ItemLoader v-if="isViewLoaded === true"  v-on:click="remove()">
            <Container>
                    <InnerLoader>
                            <Container >
            <Card_container>
              <br/>
              <br/>
              <Heading2 class="heading2-xl">  Patient Details  </Heading2>
              <br/>
                <table id="table">
                        <tbody>
                        <tr>
                        <td><Heading2>Patient  ID    :  </Heading2></td>
                         <td v-if="dataset.item.patient_id == null"> <Heading2>N/A </Heading2></td>
                        <td v-else> <Heading2>{{dataset.item.patient_id}}  </Heading2></td>
                        </tr>
                        <tr>
                        <td><Heading2> Patient Name :  </Heading2></td>
                        <td v-if="dataset.item.patient_name == null"> <Heading2>N/A </Heading2></td>
                        <td v-else> <Heading2>{{dataset.item.patient_name}}  </Heading2></td>
                        </tr>
                        <tr>
                        <td><Heading2>Patient Date of Birth :  </Heading2></td>
                         <td v-if="dataset.item.patient_dob == null"> <Heading2>N/A </Heading2></td>
                        <td v-else> <Heading2>{{dataset.item.patient_dob}}  </Heading2></td>
                        </tr>
                        <tr>
                        <td><Heading2> Time :  </Heading2></td>
                         <td v-if="dataset.item.timestamp == null" > <Heading2>{{dataset.item.timestamp}}  </Heading2></td>
                        <td v-else > <Heading2>{{dataset.item.timestamp}}  </Heading2></td>
                        </tr>
                        <tr>
                        <td><Heading2> Status :  </Heading2></td>                         
                        <td> <Heading2>{{(dataset.item.state).toUpperCase()}} </Heading2></td>
                        </tr>
                        <tr>
                        <td><Heading2> Slice Thickness :  </Heading2></td>
                         <td v-if="dataset.item.slice_thickness == ''" > <Heading2>N/A</Heading2></td>
                        <td v-else > <Heading2>{{dataset.item.slice_thickness}}  </Heading2></td>
                        </tr>
                         <tr>
                        <td><Heading2> Scanner Manufacturer :  </Heading2></td>
                         <td v-if="dataset.item.scanner_manufacturer == ''" > <Heading2>N/A</Heading2></td>
                        <td v-else > <Heading2>{{dataset.item.scanner_manufacturer}}  </Heading2></td>
                        </tr>
                         <tr>
                        <td><Heading2> Scanner Model :  </Heading2></td>
                         <td v-if="dataset.item.scanner_model == ''" > <Heading2>N/A  </Heading2></td>
                        <td v-else > <Heading2>{{dataset.item.scanner_model}}  </Heading2></td>
                        </tr>
                         <tr>
                        <td><Heading2> Series Description :  </Heading2></td>
                         <td v-if="dataset.item.series_description == ''" > <Heading2>N/A </Heading2></td>
                        <td v-else > <Heading2>{{dataset.item.series_description}}  </Heading2></td>
                        </tr>
                         <tr>
                        <td><Heading2> Study Description :  </Heading2></td>
                         <td v-if="dataset.item.study_description == ''" > <Heading2> N/A</Heading2></td>
                        <td v-else > <Heading2>{{dataset.item.study_description}}  </Heading2></td>
                        </tr>
                        
                        </tbody>
                        </table>
            </Card_container>    
                        <br/>
                        <br/>

                            </Container>
                            
                    </InnerLoader>
            </Container>
        </ItemLoader>

         <Loader class="image-loader" v-if="isImageLoaded === true"  v-on:click="removeImage()"  >
         <div>
             <img class="img-inner-fluid" v-bind:src="ImageUrl" alt=""/>
         </div>
        </Loader>


        <Card>
            <div  class="zoom-container">
            <img v-on:click="view(`${dataset.item.thumbnail}`)" class="img-fluid" v-bind:src="dataset.item.thumbnail" alt=""/>
            </div>
            <Card_container>
                <Alert v-on:click="viewdetails(`${dataset.item}`)" failed v-if="dataset.item.state ==='failed'">
                <AlertText> <i class="far fa-eye"></i> {{(dataset.item.state).toUpperCase()}}  </AlertText>
                </Alert>
                 <Alert v-on:click="viewdetails(`${dataset.item}`)"  v-else>
                <AlertText><i class="far fa-eye"></i> {{(dataset.item.state).toUpperCase()}} </AlertText>
                </Alert>
                <table id="table">
                        <tbody>
                        <tr>
                        <td><Heading> ID :  </Heading></td>
                         <td v-if="dataset.item.patient_id == null"> <Heading>N/A </Heading></td>
                        <td v-else> <Heading>{{dataset.item.patient_id}}  </Heading></td>
                        </tr>
                        <tr>
                        <td><Heading> Name :  </Heading></td>
                        <td v-if="dataset.item.patient_name == null"> <Heading>N/A </Heading></td>
                        <td v-else> <Heading>{{dataset.item.patient_name}}  </Heading></td>
                        </tr>
                        <tr>
                        <td><Heading> DOB :  </Heading></td>
                         <td v-if="dataset.item.patient_dob == null"> <Heading>N/A </Heading></td>
                        <td v-else> <Heading>{{dataset.item.patient_dob}}  </Heading></td>
                        </tr>
                        <tr>
                        <td><Heading> Time :  </Heading></td>
                         <td v-if="dataset.item.timestamp == null" > <Heading> N/A  </Heading></td>
                        <td v-else > <Heading>{{dataset.item.timestamp}}  </Heading></td>
                        </tr>
                        <tr>
                        <td><Heading> Link :  </Heading></td>
                         <td v-if="dataset.item.url == null" > <Heading>N/A</Heading></td>
                        <td v-else ><Links v-bind:href="dataset.item.url">View Report </Links></td>
                        </tr>

                        </tbody>
                        </table>
                        <br/>
            </Card_container>    
        </Card>

    </Fragment>

</template>


<style>
        .img-fluid{
             width: 100%;
             cursor: pointer;
            transition: all 0.3s;
            display: block;
            height: auto;
            transform: scale(1);        
        }
            .heading2-xl{
                font-size: 33px;
            }
        .loading-card{
            width: 400px;
        }
        .zoom-container{
               position: relative;
                margin: auto;
                overflow: hidden;
        }

        .img-fluid:hover{
               transform: scale(4.6);
        }

        .img-inner-fluid{
            text-align: center;
            padding-top: 10vh;
            width: 500px;
        }

        @media only screen and (max-width: 800px){
            .img-inner-fluid{
                width: 300px;
            }
        }

        .image-loader{
            text-align: center;
            margin: auto;
            transition: width 2s, height 4s;
        }
       
       #table {

        border-collapse: collapse;
        width: 100%;
        }

        #table td, #table th {
        border: 1px solid #ddd;
        padding: 8px;
        }

        #table tr:nth-child(even){background-color: #f2f2f2;}

        #table tr:hover {background-color: #ddd;}

        #table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #4CAF50;
        color: white;
        }
</style>

  